// Generated by Framer (5ac62b5)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/cbvC3heGD";

const cycleOrder = ["ZhpCegFfn", "Wf43KO7w8"];

const serializationHash = "framer-misop"

const variantClassNames = {Wf43KO7w8: "framer-v-1sb9b3s", ZhpCegFfn: "framer-v-n0yssn"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {Free: "ZhpCegFfn", Plus: "Wf43KO7w8"}

const getProps = ({height, id, title, width, ...props}) => { return {...props, DeAuUpW3F: title ?? props.DeAuUpW3F ?? "Plan", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "ZhpCegFfn"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, DeAuUpW3F, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "ZhpCegFfn", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-misop", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-n0yssn", className)} data-framer-name={"Free"} layoutDependency={layoutDependency} layoutId={"ZhpCegFfn"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-58389c29-c015-4427-a57c-637e65cb6404, rgb(235, 235, 235))", borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopLeftRadius: 8, borderTopRightRadius: 8, ...style}} variants={{Wf43KO7w8: {backgroundColor: "var(--token-556a66a6-7f4b-4118-b327-ed374e4a0f03, rgb(173, 31, 255))"}}} {...addPropertyOverrides({Wf43KO7w8: {"data-framer-name": "Plus"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-rcx6ky"} data-styles-preset={"cbvC3heGD"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--token-e0da01bd-4e0f-4d83-9396-a2ca91f1ef19, rgb(0, 0, 0)))"}}>FREE</motion.p></React.Fragment>} className={"framer-1aikcbq"} layoutDependency={layoutDependency} layoutId={"Unop8JXte"} style={{"--extracted-r6o4lv": "var(--token-e0da01bd-4e0f-4d83-9396-a2ca91f1ef19, rgb(0, 0, 0))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={DeAuUpW3F} variants={{Wf43KO7w8: {"--extracted-r6o4lv": "rgb(255, 255, 255)"}}} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({Wf43KO7w8: {children: <React.Fragment><motion.p className={"framer-styles-preset-rcx6ky"} data-styles-preset={"cbvC3heGD"} style={{"--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}>PRO</motion.p></React.Fragment>}}, baseVariant, gestureVariant)}/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-misop [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-misop .framer-1h3zhm3 { display: block; }", ".framer-misop .framer-n0yssn { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 8px 8px 8px 8px; position: relative; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-misop .framer-1aikcbq { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-misop .framer-n0yssn { gap: 0px; } .framer-misop .framer-n0yssn > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-misop .framer-n0yssn > :first-child { margin-left: 0px; } .framer-misop .framer-n0yssn > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 30
 * @framerIntrinsicWidth 44.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"Wf43KO7w8":{"layout":["auto","auto"]}}}
 * @framerVariables {"DeAuUpW3F":"title"}
 * @framerImmutableVariables true
 */
const Framerx6xIY1jln: React.ComponentType<Props> = withCSS(Component, css, "framer-misop") as typeof Component;
export default Framerx6xIY1jln;

Framerx6xIY1jln.displayName = "CMS / Plan Tag";

Framerx6xIY1jln.defaultProps = {height: 30, width: 44.5};

addPropertyControls(Framerx6xIY1jln, {variant: {options: ["ZhpCegFfn", "Wf43KO7w8"], optionTitles: ["Free", "Plus"], title: "Variant", type: ControlType.Enum}, DeAuUpW3F: {defaultValue: "Plan", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(Framerx6xIY1jln, [...sharedStyle.fonts])